/* You can add global styles to this file, and also import other style files */
@import "variable";

.f-w-300 {
  font-weight: 300;
}

.f-30 {
  font-size: 30px;
}

.f-16 {
  font-size: 16px;
}


.f-14 {
  font-size: 14px;
}

.f-24 {
  font-size: 24px;
}

.f-34 {
  font-size: 34px;
}

.f-w-bold {
  font-weight: bold;
}

.f-c-title {
  color: #2a2a2a;
}

.f-c-sub-title {
  color: black;
}

.f-w-400 {
  font-weight: 400;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

* {
  font-family: $font-stack;
  scroll-behavior: smooth;
}

.main-font {
  font-family: $font-stack;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #5f5f5f;

  &:hover {
    color: white;
  }

}

.ant-menu-item-selected {
  color: white;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #313131;
}

.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
  color: #1b56d3;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
  background: #f0f0f0;
}

.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

.a-delay-1000 {
  animation-delay: 1000ms;
}

/* For Chrome, Safari, and other WebKit browsers */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar color */
  border-radius: 10px; /* Rounded corners */
  border: 3px solid #f1f1f1; /* Adds padding around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color on hover */
}

/* For Firefox */
.scrollable-element {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}

.d-jus-between {
  justify-content: space-between;
}

.d-jus-end {
  justify-content: flex-end;
}

.d-jus-center {
  justify-content: center;
}

.ant-btn:hover, .ant-btn:focus {
  color: #252525;
  border-color: #252525;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  background-color: white;
  color: black;
}

.ant-spin-dot-item {
  background-color: #0043ff;
}

.ant-spin {
  color: #92adfd;
}

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  transform: rotate(45deg);
}

.loader::before {
  content: '';
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: -24px;
  animation: animloader 4s ease infinite;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  animation: animloader2 2s ease infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 0 24px white, 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 0 24px white, 24px 24px white, 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
  37% {
    box-shadow: 0 24px white, 24px 24px white, 24px 48px white, 0px 48px rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 0 24px white, 24px 24px white, 24px 48px white, 0px 48px white;
  }
  62% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px white, 24px 48px white, 0px 48px white;
  }
  75% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px white, 0px 48px white;
  }
  87% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px white;
  }
  100% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
}

@keyframes animloader2 {
  0% {
    transform: translate(0, 0) rotateX(0) rotateY(0);
  }
  25% {
    transform: translate(100%, 0) rotateX(0) rotateY(180deg);
  }
  50% {
    transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
  }
  75% {
    transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
  }
  100% {
    transform: translate(0, 0) rotateX(0) rotateY(360deg);
  }
}

